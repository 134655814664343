import HeroSection from '@components/molecules/HeroSection';
import { FormattedMessage } from 'utils/intl';

import messages from './RedarcBanner.messages';
import classes from './RedarcBanner.module.scss';

const RedarcBanner = () => (
  <HeroSection
    title={<FormattedMessage {...messages.title} />}
    subtitle={<FormattedMessage {...messages.subtitle} />}
    classes={classes}
  />
);

export default RedarcBanner;
